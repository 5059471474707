import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import * as styles from './logo-garden.module.css';
//@ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
    KontentStringValue,
    KontentRichText,
    BrandColor,
    CTALocation,
    KontentNumberValue,
    KontentRadioMultiChoice,
} from '../../../types/KontentProps';
import RichText from '../Global/RichText';
import { getMultiChoice, getColor } from '../Global/DataUtils';
import CtaLink from '../Global/CtaLink';
import { BuildLinkHref } from '../Global/BuildLinkHref';
import Logo from './Logo';
import ShowMoreArrow from '../Global/ShowMoreArrow';

interface logosType {
    value: Array<{
        elements: {
            image?: {
                value: Array<{
                    url: string;
                    name: string;
                    description: string;
                }>;
            };
            link?: { value: string };
            link_action?: { value: Array<{ codename: string }> };
        };
    }>;
}
type numberoflogostype = 'n1' | 'n1';

interface Props {
    headline: KontentStringValue;
    component_format: {
        value: {
            codename: 'box' | 'float_description';
        }[];
    };
    cta_configuration__cta_label?: KontentStringValue;
    cta_configuration__cta_location?: CTALocation;
    cta_configuration__cta_window_action?: {
        value: {
            codename: 'same_window' | 'new_window';
        }[];
    };
    cta_configuration__cta_location_anchor_point: KontentStringValue;
    description?: KontentRichText;
    font_color: BrandColor;
    floating_description_background_color: BrandColor;
    box_background_color: BrandColor;
    number_of_logos_in_row_on_desktop: KontentNumberValue;
    number_of_logos_in_row_on_mobile: KontentRadioMultiChoice<numberoflogostype>;
    logos: logosType;
    anchor: KontentStringValue;
    max_number_to_display_on_mobile: KontentNumberValue;
    control_top_margin?: KontentRadioMultiChoice<string>;
    desktop_top_margin?: KontentStringValue;
    mobile_top_margin?: KontentStringValue;
}

const LogoGarden: React.FC<Props> = ({
    headline,
    component_format,
    cta_configuration__cta_label,
    cta_configuration__cta_location,
    cta_configuration__cta_location_anchor_point,
    cta_configuration__cta_window_action,
    number_of_logos_in_row_on_desktop,
    number_of_logos_in_row_on_mobile,
    logos,
    box_background_color,
    floating_description_background_color,
    max_number_to_display_on_mobile,
    font_color,
    description,
    anchor,
    control_top_margin,
    desktop_top_margin,
    mobile_top_margin,
}) => {
    const toggleShowAll = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };
    const headingRef = useRef(null);
    const componentAnchor = anchor?.value ? anchor?.value : undefined;
    const maxLogosOnMobiles = max_number_to_display_on_mobile.value;
    const breakpoints = useBreakpoint();
    const controlMargin = control_top_margin?.value?.[0]?.codename === 'yes';
    const marginStyles = controlMargin
        ? {
              marginTop: breakpoints.md
                  ? `${desktop_top_margin?.value || 0}px`
                  : `${mobile_top_margin?.value || 0}px`,
              paddingTop: '0',
          }
        : {};

    const [showAll, setShowAll] = useState(
        maxLogosOnMobiles && !breakpoints.sm ? false : true
    );
    const logoGridstylesForDesktops = {
        gridTemplateColumns: `repeat(${
            number_of_logos_in_row_on_desktop.value
                ? number_of_logos_in_row_on_desktop.value
                : 4
        }, 200px)`,
    };

    const ctaWindowAction = getMultiChoice(
        cta_configuration__cta_window_action
    );

    const numberOfLogosInRowOnMobiles =
        getMultiChoice(number_of_logos_in_row_on_mobile) === 'n1' ? 1 : 2;
    const logoGridstylesForMobiles = {
        gridTemplateColumns: `repeat(${numberOfLogosInRowOnMobiles}, 150px)`,
    };

    const boxBackgroundColor = getColor(box_background_color);
    const FloatBoxBackgroundColor = getColor(
        floating_description_background_color
    );
    const fontColor = getColor(font_color);
    const floatTypeFormat =
        component_format?.value[0]?.codename === 'float_description'
            ? true
            : false;
    const lessThan6 = logos.value.length < 6;
    const lessThan4 = logos.value.length < 4 && breakpoints.xl;
    const backgroundColorStyles =
        floatTypeFormat && !breakpoints.xl
            ? { backgroundColor: FloatBoxBackgroundColor || '#fff' }
            : { backgroundColor: boxBackgroundColor || '#fff' };
    return (
        <section
            id={
                (floatTypeFormat && !breakpoints.xl) || !floatTypeFormat
                    ? componentAnchor
                    : undefined
            }
            className={`${styles.wrapper} ${
                floatTypeFormat && breakpoints.xl
                    ? styles.additionalSpace
                    : styles.additionalMargin
            }`}
            style={{
                ...backgroundColorStyles,
                ...marginStyles,
                ...(controlMargin ? { padding: '0' } : {}),
            }}
        >
            <div
                style={{
                    color: fontColor || 'rgb(80, 82, 81)',
                    position: 'relative',
                }}
            >
                <div
                    className={
                        floatTypeFormat && breakpoints.xl
                            ? styles.floatingDescBox
                            : ''
                    }
                    style={
                        floatTypeFormat && breakpoints.xl
                            ? { backgroundColor: FloatBoxBackgroundColor }
                            : {}
                    }
                >
                    {headline.value && (
                        <h2
                            ref={headingRef}
                            className={styles.headline}
                            id={
                                floatTypeFormat && breakpoints.xl
                                    ? componentAnchor
                                    : undefined
                            }
                        >
                            {headline.value}
                        </h2>
                    )}
                    {description?.value !== '<p><br></p>' ? (
                        <div
                            className={`${
                                floatTypeFormat && breakpoints.xl
                                    ? ''
                                    : styles.description
                            }`}
                        >
                            {RichText(description)}
                        </div>
                    ) : null}
                </div>
                {
                    <div
                        className={`${
                            lessThan6 ? styles.lessThan6 : styles.logosWrapper
                        } ${
                            floatTypeFormat && breakpoints.xl
                                ? styles.additionalpadding
                                : ''
                        }`}
                        style={{
                            ...(lessThan6 && breakpoints.xl
                                ? undefined
                                : !breakpoints.sm
                                ? logoGridstylesForMobiles
                                : breakpoints.xl
                                ? logoGridstylesForDesktops
                                : undefined),
                            ...(controlMargin ? { padding: '0' } : {}),
                        }}
                    >
                        {logos.value
                            .slice(
                                0,
                                breakpoints.sm || (showAll && !breakpoints.sm)
                                    ? logos.value.length
                                    : maxLogosOnMobiles
                            )
                            .map(({ elements }, index) => {
                                const logoUrl = elements.image?.value[0].url;
                                const logoDescription =
                                    elements.image?.value[0].description || '';
                                const logoTarget =
                                    elements.link_action?.value[0]?.codename ||
                                    '#';
                                const logoLink = elements.link?.value;

                                if (logoUrl && logoTarget) {
                                    return (
                                        <Logo
                                            key={index}
                                            logoUrl={logoUrl}
                                            logoDescription={logoDescription}
                                            logoTarget={logoTarget}
                                            logoLink={logoLink}
                                            lessThan4={lessThan4}
                                            lessThan6={lessThan6}
                                        />
                                    );
                                }

                                return null;
                            })}
                    </div>
                }
                {maxLogosOnMobiles &&
                    logos.value.length > maxLogosOnMobiles &&
                    !breakpoints.sm && (
                        <>
                            {showAll ? (
                                <div className={styles.showLessArrow}>
                                    <ShowMoreArrow
                                        btnText={''}
                                        strokeColor={fontColor || '#505251'}
                                        handleClick={() => {
                                            toggleShowAll();
                                            if (headingRef.current) {
                                                headingRef.current.scrollIntoView(
                                                    { behavior: 'instant' }
                                                );
                                            }
                                        }}
                                        transform={'0'}
                                    />
                                </div>
                            ) : (
                                <ShowMoreArrow
                                    btnText={'Show More'}
                                    strokeColor={fontColor || '#505251'}
                                    handleClick={toggleShowAll}
                                    transform={'0'}
                                />
                            )}
                        </>
                    )}
                {cta_configuration__cta_label?.value && (
                    <div className={styles.ctaContainer}>
                        <CtaLink
                            href={BuildLinkHref(
                                cta_configuration__cta_location,
                                cta_configuration__cta_location_anchor_point
                            )}
                            target={
                                ctaWindowAction === 'new_window'
                                    ? '_new'
                                    : '_self'
                            }
                            visualStyle={
                                floatTypeFormat && breakpoints.xl
                                    ? 'outlineDark'
                                    : 'outlineLight'
                            }
                        >
                            {cta_configuration__cta_label?.value}
                        </CtaLink>
                    </div>
                )}
            </div>
        </section>
    );
};

export default LogoGarden;

export const fragmentLogoGarden = graphql`
    fragment LogoGarden on kontent_item_component___logo_garden {
        elements {
            headline {
                value
            }
            component_format {
                value {
                    codename
                }
            }
            cta_configuration__cta_label {
                value
            }
            cta_configuration__cta_location {
                ...kontentCtaLocation
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            number_of_logos_in_row_on_desktop {
                value
            }
            number_of_logos_in_row_on_mobile {
                value {
                    codename
                }
            }
            max_number_to_display_on_mobile {
                value
            }
            box_background_color {
                ...kontentColor
            }
            floating_description_background_color {
                ...kontentColor
            }
            font_color {
                ...kontentColor
            }
            description {
                ...kontentRichText
            }
            anchor {
                value
            }
            logos {
                value {
                    ... on kontent_item_media___logo_garden_asset {
                        elements {
                            link_action {
                                value {
                                    codename
                                }
                            }
                            link {
                                value
                            }
                            image {
                                value {
                                    url
                                    name
                                    description
                                }
                            }
                        }
                    }
                }
            }
            control_top_margin {
                value {
                    name
                    codename
                }
            }
            mobile_top_margin {
                value
            }
            desktop_top_margin {
                value
            }
        }
    }
`;
